$primary: #8944F1;
$secondary: #E36955;
$terinary: #F1AE44;
$red: #E92100;
$dark: #221823;
$accent: #2A2828;
$grey: #B1B1B1;
$grey-2: #00000029;
$grey-3: #EEEEEE;
$grey-4: #ECEBE7;
$grey-5: #888888;
$grey-6: #484242;
$grey-7: #424242;
$grey-8:#3D3D3D;
$yellow-2:#F2F0EC;
$yellow-1: #FFF0D9;
$white: #FFFFFF;
$orange:#8F1D00;
$h1: 63px;
$h2: 50px;
$h3: 46px;
$h4: 28px;
$h5: 24px;
$h6: 22px;
$subtitle1: 20px;
$subtitle2: 16px;
$caption:14px;
$positive:#59EF4D;