/** Coverage **/
@import '../../scss/Variable.scss';
.coverage{
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
}
.coverage::before{
    content: '';
    background: $dark 0% 0% no-repeat padding-box;
    width: 33%;
    left: 0px;
    right: 0px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    opacity: .75;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
}
.coverage::after{
    content: '';
    position: absolute;
    top:0px;
    background: $grey-3;
    left: 0px;
    right:0px;
    bottom: 0px;
    width: 100%;
}
.coverage h4{
    position: relative;
    z-index: 1;
    font-size: $h5;
    color: $white;
    text-align: left;
}
.coverage .coverage-content{
    position: relative;
    z-index: 1; 
    padding-left: 30px;
}
.coverage .coverage-content-cover{
    position: relative;
    z-index: 1; 
    padding-left: 30px;
}
.coverage .coverage-content p{
    font-size: $h4;
    color:$grey-6;
}
.self-center{
    align-self: center;
}
.information-image-card,.information-image-card-2,.information-image-card-3{
    position: relative;
}
.information-image-card-2::after{
    content:'';
   // background-color: #D97E73;
    opacity: 75%;
    position: absolute;
    left:0px;
    right:0px;
    top:0px;
    bottom: 0px;
}
.information-image-card-3::after{
    content:'';
    // background-color: #E5A646;
    opacity: 75%;
    position: absolute;
    left:0px;
    right:0px;
    top:0px;
    bottom: 0px;
}
.information-image-card::after{
    content:'';
    // background-color: #68BAB9;
    opacity: 75%;
    position: absolute;
    left:0px;
    right:0px;
    top:0px;
    bottom: 0px;
}
.information-content .information-content-title{
    position: absolute;
    top: 68%;
    padding-left: 20px;
    z-index: 1;
    padding-right: 20px;
    text-align: center;
    font-size: 31px;
    color: #ffff;
}
.information-content .button{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}
.btn-primary{
    background: $dark !important;
    padding-left: 30px !important;
    padding-right: 50px !important;
    font-size: $subtitle1;
    color: $white;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    border-radius: 0px;
    padding-top:12px !important;
    padding-bottom: 12px !important;
}
/** Aims **/
.aims-title{
    font-size: $h2;
    color: $grey-6;
    font-weight: bold;
}
.sub-caption{
    font-size: $subtitle2;
    color: $primary;
}
.aim-card{
    margin-top: 30px;
    text-align: -moz-center;
    text-align: center;
}
.aim-card .aim-icon{
    background: $yellow-1 0% 0% no-repeat padding-box;
  width: fit-content;
  padding: 20px;
  margin-bottom: 20px;
}
.aim-card .aim-icon img{
    width: 48px;
}
.aim-content h4{
    font-size: $h5;
    color:$grey-6;
    font-weight: bold;
    margin-top:10px;
}
.aim-content p{
    font-size: $subtitle2;
    color: $grey-5;
    margin-top:14px;
}
.aim-icon-center{
    width: 100%;
    display: flex;
  justify-content: center;
}

.btn-secondary{
    background: $secondary !important;
    padding-left: 20px !important;
    padding-right: 30px !important;
    padding-top:10px !important;
    padding-bottom: 10px !important;
    font-size: $subtitle1 !important;
    color:$white !important;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    outline: none !important;
  border: none !important;
}
.trust-itent{
    background: $grey-8;
    text-align: center;
}
.section-title{
    font-size: $h3;
    color: $terinary;
    font-weight: bold;
    margin-bottom: 30px;
}
.trust-itent p{
    margin-top:30px;
    font-size: $subtitle1;
    color: $white;
    text-align: center;
}
/** News **/
.news-card{
    margin-top: 30px;
}
.news-title{
    font-size: $h4;
    color: $grey-8;
    font-weight: bold;
}
.news-date{
    color: $secondary;
    font-size: $caption;
    margin-top: 10px;
}
.news-content{
    padding-top:10px;
}
.news-content p{
    font-size: $subtitle2;
    color: $accent;
    margin-top: 10px;
}
.text-subtitle2{
    font-size: $subtitle2 !important;
}
.mtop-3o{
    margin-top: 30px;
}
.past-news-card-bg{
    background: $yellow-2 0% 0% no-repeat padding-box;
    margin-top:30px;
}
.past-news-card{
    background: $yellow-2 0% 0% no-repeat padding-box;
}
.past-news-card .past-news{
    padding:30px;
}
.column-space-right{
    margin-right: 20px;
}
.past-news-card{
    margin-left: 20px;
    padding-top:30px;
    padding-bottom: 30px;
}
/** Mobile Responsiveness **/
@media (max-width:800px) {
    .coverage::before {
       background: transparent !important;
    }
    .coverage h4{
        color:$grey-6;
    }
    .no-padding-left{
        padding-left: 0px !important;
    }
    .coverage .coverage-content{
        padding-left: 15px !important;
        padding-top:10px !important;
    }
    .mobil-hide{
        display: none;
    }
    .coverage .coverage-content p {
        font-size: 24px;
        line-height: 32px;
    }
    .coverage-content-cover{
        margin-top:20px;
    }
    .mobile-space-top{
        margin-top:30px;
    }
    .aims-title {
        font-size: 36px;
    }
    .section-title {
        font-size: 36px;
    }
    .trust-itent p {
        font-size: 18px;
    }
    .btn-secondary{
        font-size: 18px !important;
    }
    .column-space-right {
        margin-right: 0px;
    }
    .past-news-card-bg{
        background: transparent !important;
    }
    .past-news-card {
        margin-left: 0px;
    }
}
@media only screen and (min-width:1450px) and (max-width:1600px) {
    .coverage::before {
        width:28% !important;
    }
}

@media only screen and (min-width:1000px) and (max-width:1450px) {
    .coverage::before {
        width:26% !important;
    }
    .banner{
        background-size: 100% 100% !important;
    }
}

@media only screen and (min-width:1600px) and (max-width:1700px) {
    .coverage::before {
        width:30% !important;
    }
}

/**
Responsiveness **/
@media only screen and (min-width:900px) and (max-width:1300px) {
    .coverage .coverage-content p {
        font-size: 24px !important;
    }
    .aims-title {
        font-size: 45px !important;
    }
    .aim-content p {
        font-size: 16px !important;
    }
    .btn-secondary{
        font-size: 16px !important;
    }
    .trust-itent p {
        font-size: 16px !important;
    }
    .news-title {
        font-size: 24px !important;
    }
    .past-news-title{
        font-size: 22px !important;
    }
    p {
        font-size: 16px !important;
    }
}