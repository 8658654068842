@import '../../scss/Variable.scss';
.footer{
    background: $grey-8;
    padding: 30px;
}
.footer-title{
    font-size: $subtitle2;
    color:$white;
    text-align: center;
    margin-bottom: 0px;
}