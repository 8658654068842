@import '../../scss/Variable.scss';
.banner{
    background-image: url('../../assets/Banner-image.png');
    min-height: 620px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.banner-content{
    position: absolute;
  bottom: 20%;
  left: 28%;
}
.banner-content h3{
    text-align: center;
  font-size: $h1;
  letter-spacing: 0px;
  color: $white;
  text-shadow: 4px 4px 6px #00000029;
  font-weight: 600;
}
.banner h3 .positive{
  color:$positive;
}
.banner h3 .orange{
  color:#FF822E;
}
@media only screen and (min-width:900px) and (max-width:1600px) {
  .banner-content{
    position: absolute;
  bottom: 20%;
  left: 26%;
}
}
/** Mobile Responsiveness **/
@media (max-width:700px) {
  .banner-content {
    position: static !important;
    background: #FFFFFFA8;
    padding: 20px;
    margin-top: 120px;
    margin-bottom: 120px;
  } 
  .banner{
    min-height: auto !important;
    background-size: cover;
    background-position: center;
  }
  .banner-content h3{
    font-size: 36px;
    color:$grey-7;
  }
}
