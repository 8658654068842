@import './Variable.scss';
.about-us-content-block p{
    font-size: $subtitle2;
    color:$dark;
    margin-bottom: 30px;
}
.aboutus-title{
    font-size: $subtitle1;
    color: $dark;
    margin-bottom: 30px !important;
}
.card-space-left{
    margin-left: 10px;
}
.card-space-right{
    margin-right: 10px;
}
.gallery-block-title{
    font-size: $subtitle1;
    color: $grey-6;
    margin-top:20px;
}
.gallery-image-block{
    margin-top:30px;
}
.about-support p{
    display: flex;
}
.about-support p span img{
    width:24px;
    margin-right: 10px;
}
.contactus-title{
    font-size: $h3;
    color:$grey-6;
    font-weight: bold;
}
.contact-us-block p{
    font-size: $subtitle2;
    color:$grey-6;
    margin-top:20px;
}
.contact-us-icons{
    margin-top:30px;
}
.contact-us-icons a img{
    width: 32px;
  margin-right: 30px;
  height: 32px;
}
.form-control {
    background: #F2F0EC 0% 0% no-repeat padding-box;
    padding:20px;
    font-size: $subtitle2;
    color:$grey-6;
}
.contactusinfo-details{
    clip-path: polygon(0 0, 100% 0%, 94% 100%, 0% 100%);
  padding: 40px;
  color: #ffffff;
  min-height: 260px;
  margin-top:30px;
}
.contactusinfo-details2{
    background: #E36955 0% 0% no-repeat padding-box;
}
.contactusinfo-details1{
    background: #50BAC3 0% 0% no-repeat padding-box;
}
.contactusinfo-details3{
    background: #F1AE44 0% 0% no-repeat padding-box;
}
.contactusinfo-details .contact-info-title{
    font-size: $h6;
    color:$white;
    font-weight: bold;
}
.contactusinfo-details p{
    font-size: $subtitle2;
    color:$white;
    margin-top:30px;
}

@media (max-width:800px) {
    .contactus-title {
        font-size: 32px;
    }
    .mobile-space-top{
        margin-top:30px;
    }
}
.slick-slider .gallery-image{
    margin:20px;
}
.bank-details{
    background: #FFF0D9 0% 0% no-repeat padding-box;
  border: 1px solid #D1D1D1;
  border-radius: 19px;
  padding: 30px;
  margin-top: 50px;
}
.bank-details-content{
    display: flex;
}
.bank-details-content h3{
    font-size: $subtitle1;
    letter-spacing: 0.5px;
    color: #484242;
}
.bank-details-title{
    font-size: $h4;
    margin-bottom: 30px;
    color: $secondary;
}
@media only  screen and (min-width:800px) {
    .border-bank-right{
        border-right:1px solid #D1D1D1;
    }
    .q-pr-md{
        padding-right: 30px;
    }
    .q-pl-md{
        padding-left: 30px;
    }
    .width-28{
        width:28%
    }
    .width-72{
        width: 72%;
    }
    .width-43{
        width:49%;
    }
    .width-67{
        width:61%;
    }
}
@media only  screen and (max-width:800px) {
    .width-28{
        width:31%
    }
    .width-72{
        width: 70%;
    }
    .width-43{
        width:30%;
    }
    .width-67{
        width:70%;
    }
    .bank-details-content h3 {
        font-size: 15.5px !important;
    }
    .bank-details {
        padding: 15px !important;
    }
    .bank-details-content{
        align-self: center;
  align-items: center;
    }
}
