@import '../../scss/Variable.scss';
/** Topbar **/
.topbar{
    position: relative;
    padding: 10px;
}
.topbar::before{
    content: '';
    background: $grey-7 0% 0% no-repeat padding-box;
    width: 40%;
    left: 0px;
    right: 0px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    -webkit-clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 98% 100%, 0% 100%);
}
.topbar-block{
    display: flex;
    position: relative;
    z-index: 1;
}
.topbar-block .topbar-icon{
    margin-right: 20px;
}
.topbar-icon img{
    width: 24px;
}
.topbar-icon span{
    font-size: $caption;
    color:$grey-4;
    margin-left: 10px;
}
.social-icons{
    position: relative;
    text-align: end;
    z-index: 1;
    margin-bottom: 0px;
}
.topbar::after{
    content: '';
    position: absolute;
    top:0px;
    background: $grey-3;
    left: 0px;
    right:0px;
    bottom: 0px;
    width: 100%;
}
.social-icons img{
    width:26px;
    margin-left: 10px;
}
.navbar-brand img{
    width:90px;
}
.navbar-brand{
    display: flex;
}
.navbar-brand span{
    font-size: $subtitle1;
    color:$orange;
    margin-left: 10px;
    line-height: 24px;
  font-weight: bold;
  align-self: center;
}
.navbar-expand-lg .navbar-nav .nav-link{
    font-size: $subtitle2 !important;
    color: $grey-7;
    padding-left: 8px !important;
    padding-right: 9px !important;
}

.donate-cause-btn{
    background: $secondary;
    padding-left: 10px !important;
    padding-right: 20px !important;
    padding-top:10px !important;
    padding-bottom: 10px !important;
    font-size: $subtitle2 !important;
    color:$white !important;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
}
.navbar{
    padding-top: 15px !important;
}
/**
Responsiveness **/
@media only screen and (min-width:900px) and (max-width:1300px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 15px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    .topbar-icon span {
        font-size: 12px !important;
    }
    .topbar-block .topbar-icon {
        margin-right: 10px;
    }
    .topbar-icon img {
        width: 18px;
    }
}
@media (max-width:800px) {
    .topbar::before {
        width: 100% !important;
        clip-path: none !important;
    }
    .navbar-toggler{
        box-shadow: none !important;
        outline: none !important;
        // border:none !important;
    }
    .topbar-block{
        display: block !important;
    }
    .social-icons img {
        width: 18px !important;
        margin-left: 5px !important;
      }
      .navbar {
        padding-top: 5px !important;
      }
      .navbar-brand img {
        width: 80px;
      }
      .navbar-brand span {
        font-size: 18px;
        line-height: 18px;
      }
      .topbar {
        padding-left: 0px;
        padding-right: 0px;
      }
      .topbar-block .topbar-icon {
        margin-right: 0px;
      }
      .social-icons {
        padding-left: 10px;
      }
}
