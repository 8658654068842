@import './Variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
body{
    font-family: 'Poppins', sans-serif;
}

@font-face {
    font-family: 'SitaFont';
    src: url('./Sitka-Small.ttf')  format('truetype'), /* Safari, Android, iOS */
}
.sita-font{
    font-family: SitaFont;
}
.self-center{
    align-self: center;
}
.pt-80{
    padding-top: 80px;
}
.pb-80{
    padding-bottom: 80px;
}
.page-title{
    font-size: $h2;
    color: $grey-6;
    font-weight: bold;
}
.breadcrumb{
    padding-top:100px;
    text-align: center;
    padding-bottom: 100px;
    background: #EEEEEE 0% 0% no-repeat padding-box;
}
.btn-secondary{
    background: $secondary !important;
    padding-left: 20px !important;
    padding-right: 30px !important;
    padding-top:10px !important;
    padding-bottom: 10px !important;
    font-size: $subtitle1 !important;
    color:$white !important;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    outline: none !important;
  border: none !important;
  border-radius: 0px !important;
}
.btn-primary{
    background: $dark !important;
    padding-left: 30px !important;
    padding-right: 50px !important;
    font-size: $subtitle1;
    color: $white;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    -webkit-clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 96% 100%, 0% 100%);
    border-radius: 0px;
    padding-top:12px !important;
    padding-bottom: 12px !important;
    border-radius: 0px !important;
    outline: none !important;
    border: none !important;
}
.slick-arrow{
    border-radius: 50%;
}
@media (max-width:700px) {
    .slick-prev::before, .slick-next::before {
        font-family: 'slick';
        font-size: 24px !important;
    }
    .slick-next {
        right: 12px !important;
    }
    .slick-prev{
        left: 4px !important;
    }
    html{
        overflow-x: hidden;
    }
}
@media (min-width:700px) {
    .slick-prev::before, .slick-next::before{
        font-size: 32px !important;
    
    }
}
.slick-prev::before, .slick-next::before {
    font-family: 'slick';
    line-height: 1;
    opacity: 1 !important;
    color: #E36955 !important;
}
.gallery-component {
    background: #EEEEEE;
}